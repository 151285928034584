import * as React from 'react';

import { isNil } from 'lodash';
import { Alert, IconButton, Divider, Box } from '@mui/material';
import { Close, ContentCopy, Done } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { useToast } from '../contexts/ToastContext';
import { GiftCardDetails } from '../../types/dataTypes';

type Props = {
  info?: GiftCardDetails;
};

const GiftCardToastInfo: React.FC<Props> = ({ info }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const copyCardNumber = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    } catch (error) {
      alert(`Failed to copy text: ${error}`);
    }
  };

  return !info ? null : (
    <>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <div style={{ fontWeight: 'bold' }}>{t('GiftCardToastInfo.cardNumber')}</div>
          {info.cardNumber}
          <IconButton color="primary" disabled={isCopied} size="small" onClick={() => copyCardNumber(info.cardNumber)}>
            {isCopied ? <Done /> : <ContentCopy />}
          </IconButton>
        </Box>
        <Box>
          <div style={{ fontWeight: 'bold' }}>{t('GiftCardToastInfo.amount')}</div>
          {info.amount}
        </Box>
        <Box>
          <div style={{ fontWeight: 'bold' }}>{t('GiftCardToastInfo.validBefore')}</div>
          {info.validBefore}
        </Box>
      </Box>
      <Divider />
      <Box>
        <div style={{ fontWeight: 'bold' }}>{t('GiftCardToastInfo.authCode')}</div>
        {info.authCode}
      </Box>
    </>
  );
};

const Toast: React.FC = () => {
  const { toast, clearToasts } = useToast();

  const severity = React.useMemo(() => {
    if (toast?.type === 'error') {
      return 'error';
    }

    if (toast?.type === 'warning') {
      return 'warning';
    }

    if (toast?.type === 'info') {
      return 'info';
    }

    if (toast?.type === 'success') {
      return 'success';
    }

    return 'info';
  }, [toast]);

  if (isNil(toast)) {
    return null;
  }

  return (
    <Alert
      severity={severity}
      sx={{ marginBottom: 2 }}
      action={
        <IconButton color="inherit" size="small" onClick={clearToasts}>
          <Close />
        </IconButton>
      }>
      {toast.text}
      <GiftCardToastInfo info={toast.giftCardDetails} />
    </Alert>
  );
};

export default Toast;
