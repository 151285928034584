import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

type Props = {
  type: string;
};

const EmailLink = () => {
  return <a href="mailto:asiakaspalvelu@ikh.fi">asiakaspalvelu@ikh.fi</a>;
};

const Text: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  if (type === 'digital') {
    return <Trans components={[<EmailLink />, <Typography variant="h4" />]}>{t('GiftCardInfo.digital')}</Trans>;
  }
  if (type === 'physical') {
    return <Trans components={[<EmailLink />, <Typography variant="h4" />]}>{t('GiftCardInfo.physical')}</Trans>;
  }
  if (type === 'gift-card-update') {
    return <Trans components={[<EmailLink />, <Typography variant="h4" />]}>{t('GiftCardInfo.giftCardUpdate')}</Trans>;
  }
  if (type === 'gift-card-search-1') {
    return (
      <Trans components={[<Typography variant="h4" sx={{ marginBottom: 5 }} />]}>
        {t('GiftCardInfo.giftCardSearch1')}
      </Trans>
    );
  }
  if (type === 'gift-card-search-2') {
    return <Trans>{t('GiftCardInfo.giftCardSearch2')}</Trans>;
  }

  return <Trans>{t('GiftCardInfo.giftCardStart')}</Trans>;
};

const GiftCardInfo: React.FC<Props> = ({ type }) => {
  return <Text type={type} />;
};

export default GiftCardInfo;
